

// checkout

$checkout-pad: 5px;

.checkout-container {

	a {
		color: $brand-info;
	}

	.breadcrumb {
		background-color: transparent;
		padding-left: 0;
		font-size: .9em;
		color: $gray-light;

		
	}

	h1 {
		font-size: 2em;
		margin: 0;
	}

	.header {
		text-align: center;
		padding: 10px 0;
		.checkout-logo {
			@extend .img-responsive;
			
			height: 60px; 
		}
	}

	.checkout-customer,
	.payment-container {
		.row {
			margin-left: -$checkout-pad;
			margin-right: -$checkout-pad;
		}

		.columns {
			padding: 0 $checkout-pad;
		}

		.form-control {
			border-radius: $checkout-pad;
			height: 42px;
		}

		.form-mar {
			margin-bottom: 5px;
		}
	}

	.btn-submit {
		@extend .btn-info;
		@extend .btn-lg;
		@extend .btn-block;

		border-radius: $checkout-pad;
		font-size: .9em;
		font-weight: 600;

 		@include bp(small) {
 			margin-top: 20px;
 		}
	}

	.cart-summary {

		@include bp(small_med) {
			margin-top: 20px;
		}
 
		.panel-heading {
			padding-left: 23px;
		}

		.table {
			tbody > tr > td {
				border: 0;
			}

			td:last-child {
				text-align: right;
			}
		}

		.cart-sub-total {
			font-size: .9em;
			color: $gray-light;
			margin-top: 10px;
			margin-bottom: 0;
			border-top: solid 1px $gray-lighter;
			
			
			td {
				padding-top: 20px;
				padding-bottom: 20px;
				
			}
		}

		.cart-total {
			border-top: solid 1px $gray-lighter;
			font-size: 1.4em;
			td {
				padding-top: 20px;	
			}
			
		}

	}

	.footer {

		a {
			font-size: .8em;
			margin-right: 20px;
		}
	}
}

.customer-details {
	p {
		font-size: 13px;
	}
}

.loading-overlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255,255,255,.9);
	z-index: 9999;
	display: none;
	border-radius: 5px;

	&.active {
		display: block;
	}

	.loading-container {
		@include center-content(430px,222px);
		text-align: center;

		.fa {
			margin-bottom: 20px;
			font-size: 2em;
		}
	}
}
