// cart nav widget

.cart-nav-widget {
	float: right;
	border-left: solid 1px rgba(255,255,255,.2);
	font-size: 30px;
	position: relative;

	.nav-item {
		display: block;
		padding: 18px 26px;
		color: $white;

		@include transition(.6s);

		&:hover {
			background-color: rgba(255,255,255,.2);
		}

		&.nav-desktop {
			display: none;
		}

		@include bp(large) {
			&.nav-mobile {
				display: none;
			}

			&.nav-desktop {
				display: block;
			}
		}
	}

	.badge {
		position: absolute;
		top: 18px;
		right: 13px;
		color: $brand-danger;
		background-color: #fff;
	}
}

.cart-dropdown-menu {
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	overflow-x: hidden;
	background-color: #fff;
	border-left: solid 1px #eee;
	border-top: 0;
	border-bottom: 0;
	border-right: 0;
	float: right;
	width: 350px;
	z-index: 100;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: stretch;
	justify-content: flex-start;
	align-items: stretch;

	@include bp(small_med) {
		// never show in mobile
		display: none !important;
	}

	.shop-widget {
		.fa-times {
			cursor: pointer;
		}

		td {
			border-top: 0 !important;
			font-size: 12px;
			vertical-align: middle;

			.cart-item-remove {
				display: inline-block;
				padding-top: 6px;
				margin-left: 10px;
				font-size: 16px;
				cursor: pointer;
			}

			.cart-item-remove,
			.cart-item-price {
				color: #9d9d9d;
			}
		}

		img {
			max-height: 50px;
		}
	}

	.widget-total {
		text-align: center;
		margin: 30px 10px;
	}

	.widget-checkout {
		background-color: $brand-danger;
		color: $white;
		margin: 0 10px;
		padding: 0;
		display: block;

		a {
			color: $white !important;
			text-align: center;
			width: 100%;
			display: block;
			padding: 8px;

			&:hover {
				color: $brand-info;
			}
		}
	}
}

// cart

.alert-primary {
	color: $brand-primary;
	border: solid 1px lighten($brand-primary,20%);
	background-color: lighten($brand-primary,40%);
	border-radius: 3px;

}

.shop-widget {

	&.table {
		margin-bottom: 0;

		tbody,
		thead {
			tr {

				td,
				th {
					vertical-align: middle;
					font-size: .9em;

					@include bp(small) {
						display: block;
					}

					&:last-child {
						text-align: right;
					}
				}

			} 
		}
	}
	
	.img-responsive {
		max-width: 50px;
	}
}

.cart-img {
	height: 100px;
	width: 100px;

	@include bg-img(contain,center,no-repeat);

	&.thumbnail {
		border: 0 none;
		margin: 0 10px 0 0;
	}
}

.td-sm {
	text-align: right;
}

.btn-cart {
	@include bp(small) {
		margin-bottom: 10px;
	}
}

.widget-checkout.widget-lg {
	border-bottom: solid 1px #ddd;
}

.cart-summaries {
	font-size: 30px;
}

.cart-sum-right {
	width: 20%;

}

.promo-container {
	padding: 20px 8px;
	border-top: solid 1px $gray-lighter;
}

.cart-ok {
	margin-top: 20px;
}