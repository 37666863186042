.cd-field,
.cd-field-2 {
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-display: flex;
	-webkit-align-items: center;
	-webkit-justify-content: center;
	margin-bottom: 15px;
	color: #999999;
	cursor: not-allowed;

	@extend .btn;
	@extend .btn-default;
	padding: 10px !important;

	i {
		height: 30px;
		width: 30px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		-webkit-display: flex;
		-webkit-align-items: center;
		-webkit-justify-content: center;
		font-size: 12px;
		margin-left: 10px;
		background-color: $gray-light;
		color: white;
		font-style: normal;

		&:hover {
			cursor: pointer;
		}

		&:before {
			content: 'YES';
			
		}

		&.cdloading {

			&:before {
				content: "\f110";
				
			}
		}

		&.cdalert {
			box-shadow: 0px 0px 0 4px $brand-danger;
			
		}

		&.cdok {
			background-color: $brand-success;
			
			&:before {
				font-family: "fontawesome";
				content: "\f00c";
			}
		}
	}
}

.other-phone {
	display: none;
}





