@mixin center-content($width, $height) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -$width / 2;
  margin-top: -$height / 2;
  width: $width;
  height: $height;
}

@mixin transition($transition) { //$transition: all .5s ease 0s
  -moz-transition: $transition;
  -webkit-transition: $transition;
  -ms-transition: $transition;
  transition: $transition;
  
}

@mixin greyscale($value:100%) {
  -moz-filter: grayscale($value);
  -webkit-filter: grayscale($value);
  filter: grayscale($value);
}

@mixin bg-img($size, $position, $repeat) {
  background-size: $size;
  background-position: $position;
  background-repeat: $repeat;
}

@mixin translate-xy($x, $y) {
	-webkit-transform:translate($x, $y);
	-moz-transform:translate($x, $y);
  -ms-transform:translate($x, $y);
	transform:translate($x, $y);
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  &.infinite {
	  -webkit-animation-iteration-count: infinite;
	  animation-iteration-count: infinite;
	}
}
%animated-fast {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}



@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    top: -999900px;
    display: none;

  }

  70% {
   opacity: 0;
   top: 0;
   display: block;
  }

  100% {
    opacity: 1;
    top: 0;
    display: block;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: -999900px;
    display: none;

  }

  70% {
   opacity: 0;
   top: 0;
   display: block;
  }

  100% {
    opacity: 1;
    top: 0;
    display: block;
  }
}

%fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

%flex {
  display: -webkit-flex;
  display: flex;
}

%align-center {
  align-items: center;
  -webkit-align-items: center;
}

@mixin justify-content($value) {
  justify-content: $value;
  -webkit-justify-content: $value;
}

%clearfix {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

%btn-cta {
  text-shadow: 0 1px 0 rgba(0,0,0,.3);
  font-family: $headings-font-family;
  font-weight: 600;

  @include transition(.1s);

  &:hover {
    @include translate-xy(0, -2px);
  }

  &:active,
  &:focus {
    outline: 0 !important;
    @include translate-xy(0, 2px);
    @include box-shadow(none !important);
  }
}

