// Breakpoint mixins
@mixin bp($point) {
	// assume that the default is 'small'
	// usage: @include bp(large) { ... }
	@if $point == small { // ... , 480px
		@media only screen and (max-width: $screen-xs-max) { @content; }
	}
	@if $point == small_med { // ... , 991px
		@media only screen and (max-width: $screen-sm-max) { @content; }
	}
	@if $point == medium { // 641px, 1024px
		@media only screen and (min-width: $screen-sm-min) { @content; }
	}
	@else if $point == large { // 1025px, 1440px
		@media only screen and (min-width: $screen-md-min) { @content; }
	}
	@else if $point == xlarge { // 1441px, 1920px
		@media only screen and (min-width: $screen-lg-min) { @content; }
	}
	@else if $point == xxlarge { // 1921px and up
		@media only screen and (min-width: 1600px)  { @content; }
	}
	@else if $point == retina {
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
		only screen and (min--moz-device-pixel-ratio: 1.5),
		only screen and (min-device-pixel-ratio: 1.5) { @content; }
	}
	@else if $point == iphone { // an iPhone 5
		@media (device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) { @content }
	}
}