/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Ville Ristimäki (@villeristi)
 * 
 */


// ====================================================================================================================
// LESS SNIPPETS
// ====================================================================================================================*/


//
// Transition
//

@mixin transition( $duration: 1s, $easing: ease ) {

  -webkit-transition: all $duration $easing;
     -moz-transition: all $duration $easing;
      -ms-transition: all $duration $easing;
       -o-transition: all $duration $easing;
          transition: all $duration $easing;

}


//
// Border-radius
//

@mixin border-radius( $radius: 5px ){

  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;

}


/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/


.flex-container,
.flex-slider{

  a:hover,
  a:focus{

    outline: none;

  }

}

.slides,
.flex-control-nav,
.flex-direction-nav {
  
  margin: 0; 
  padding: 0; 
  list-style: none;

}

.flex-pauseplay span {

  text-transform: capitalize;

}





/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/


.flexslider {

  margin: 0; 
  padding: 0;

  .slides {

    > li {

      display: none; 
      -webkit-backface-visibility: hidden;

    }

    img{

      width: 100%;
      display: block;

    }


    //
    // Clearfix for the .slides element
    //

    &:after{

      content: "\0020"; 
      display: block; 
      clear: both; 
      visibility: hidden; 
      line-height: 0; 
      height: 0;


    }

    html[xmlns] &{
      
      display: block;

    }

    * html &{
      
      height: 1%;

    }


    
     // 
     // No JavaScript Fallback
     // If you are not using another script, such as Modernizr, make sure you
     // include js that eliminates this class on page load
     // 
    

    .no-js &{

      > li{

        &:first-child{

          display: block;

        }
      }
    }
  }
}

/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/



.flex-viewport { 

  max-height: 2000px; 

  @include transition();

  .loading &{

    max-height: 300px;

  }
}

.carousel li { 

  margin-right: 5px; 

}


//
// Direction Nav
//

.flex-direction-nav {

  *height: 0;

  
  //
  // Global styles
  //

  a  { 

    text-decoration:none; 
    display: block; 
    width: 40px; 
    height: 40px; 
    margin: -20px 0 0; 
    position: absolute; 
    top: 50%; 
    z-index: 10; 
    overflow: hidden; 
    opacity: 0; 
    cursor: pointer; 
    color: rgba(0,0,0,0.8); 
    text-shadow: 1px 1px 0 rgba( 255, 255, 255, 0.3 );

    @include transition( 0.3s, ease-in-out );

    &:before  { 

      font-family: "fontawesome"; 
      font-size: 40px; 
      display: inline-block; 
      content: '\f104';

    }

    &.flex-next:before { 

      content: '\f105';

    }

  }


  //
  // Prev
  //

  .flex-prev { 

    left: -50px;

  }


  //
  // Next
  //

  .flex-next { 
    
    right: -50px; 
    text-align: right;

  }


  //
  // Hover states
  //

  .flexslider:hover &{
    
    .flex-prev { 

      opacity: 0.7; 
      left: 10px;

      &:hover{
        
        opacity: 1;

      }

    }
    
    .flex-next { 

      opacity: 0.7; 
      right: 10px;

      &:hover{
        
        opacity: 1;
        
      }

    }

  }


  //
  // Disabled
  //

  .flex-disabled { 

    opacity: 0!important; 
    filter: alpha(opacity=0); 
    cursor: default;

  }
}


//
// Pause/Play
//

.flex-pauseplay a {

  display: block; 
  width: 20px; 
  height: 20px; 
  position: absolute; 
  bottom: 5px; 
  left: 10px; 
  opacity: 0.8; 
  z-index: 10; 
  overflow: hidden; 
  cursor: pointer; 
  color: #000;

  &:before { 

    font-family: "flexslider-icon"; 
    font-size: 20px; 
    display: inline-block; 
    content: '\f004';

  }

  &:hover {

    opacity: 1;

  }

  .flex-play:before { 

    content: '\f003';

  }

}


//
// Control Nav
//

.flex-control-nav {

  width: 100%; 
  position: absolute; 
  bottom: -40px; 
  text-align: center;

  li {
    
    margin: 0 6px; 
    display: inline-block; 
    zoom: 1; 
    *display: inline;

  }

}


//
// Control paging
//

.flex-control-paging li {

  a {

    width: 11px; 
    height: 11px; 
    display: block; 
    background: #666; 
    background: rgba(0,0,0,0.5); 
    cursor: pointer; 
    text-indent: -9999px;

    @include box-shadow( 0, 0, 3px, rgba(0,0,0,0.3) );
    @include border-radius( 20px );

    &:hover { 

      background: #333; 
      background: rgba(0,0,0,0.7);

    }

    &.flex-active { 

      background: #000;
      background: rgba( 0, 0, 0, 0.9 );
      cursor: default;

    }
  }
}


.flex-control-thumbs {

  margin: 5px 0 0; 
  position: static; 
  overflow: hidden;

  li {

    width: 25%; 
    float: left; 
    margin: 0;

  }

  img {
  
    width: 100%; 
    display: block; 
    opacity: .7; 
    cursor: pointer;

    @include transition();

    &:hover {opacity: 1;}

  }

  .flex-active {

    opacity: 1; 
    cursor: default;

  }
}





/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/

@media screen and ( max-width: 860px ) {

  .flex-direction-nav{

    .flex-prev { 

      opacity: 1; 
      left: 10px;

    }

    .flex-next { 

      opacity: 1; 
      right: 10px;

    }
  }
}
