// catalogue

.catalogue-outer-container {
	border-top: solid 1px $gray-lighter;
	float: left;
	width: 100%;
	padding-bottom: 50px;
}

.catalogue-master-container {
	max-width: 1170px;
	padding: 20px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;

	@include bp(medium) {
		flex-direction: row;
	}

	.catalogue-col-1 {
		@extend .col-md-2;
	}

	.catalogue-col-2 {
		@extend .col-md-10;

		.catalogue-row {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&.catalogue-widget-container {
		text-align: center;
		
		.catalogue-item-container {

			padding-left: 20px;
			padding-right: 20px;
			vertical-align: top;

			@include bp(medium) {
				width: 50%;
				display: inline-block;
			}

			@include bp(large) {
				width: 22%;
			}
		}
	}

	.catalogue-item-container {
		@extend .col-sm-3;
		padding: 0 10px;

		@include bp(medium) {
			width: 30%;
		}
	}

	.catalogue-item {
		padding: 0 0 20px 0;
		overflow: hidden;
		background-color: $white;
		border: solid 1px $gray-lighter;
		position: relative;
		height: 430px;
		margin-bottom: 40px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-content: center;

		@include bp(large) {
			padding: 0 50px 20px 50px;
		}

		&.sold-out,
		&.new,
		&.free-shipping,
		&.wine-club-exclusive {
			&:after,
			&:before {
				position: absolute;
				top: 10px;
				color: $white;
				border-radius: 50%;
				width: 64px;
				height: 64px;
				text-align: center;
				font-size: .8em;
				font-weight: 600;

				@extend %flex;
				@extend %align-center;
				@include justify-content(center);
			}
		}

		&.sold-out,
		&.new {
			&:after {
				right: 10px;
			}
		}


		&.free-shipping,
		&.wine-club-exclusive {
			&:before {
				left: 10px;
			}
		}

		&.sold-out {
			&:after {
				content: "Sold Out";
				background-color: $brand-danger;

			}

			.catalogue-widget-container {
				display: none;
			}
		}

		&.new {
			&:after {
				content: "NEW!";
				background-color: $brand-warning;
			}
		}

		&.free-shipping {
			&:before {
				content: "Free Shipping";
				background-color: $brand-success;
			}
		}

		&.wine-club-exclusive {
			&:before {
				content: "Wine Club Exclusive";
				background-color: #957dad;
				font-size: .7em;
				font-weight: 400;
			}
		}

		.catalogue-img-container {
			background-image: url('../../catalogue/shop_v2/img/loading.gif');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 35px;
			margin-bottom: 20px;
		}
	}


	.master-img {
		height: 264px;
		display: block;
		
		@include bg-img(contain, center bottom, no-repeat);
	}

	.catalogue-title {
		font-size: 14px;
		margin: 0;
	}

	.catalogue-body {
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;

		@include transition(.6s);

		.catalogue-content {
			text-transform: uppercase;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;

			&:hover {
				text-decoration: none;
			}
		}

		.catalogue-add-to-cart {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.catalogue-price {
				flex-grow: 1;
				color: #8b7867;
				font-size: 14px;
			}
		}
	}

	.btn-buy,
	.btn-more {
		@extend .btn-sm;
		padding-top: 10px;
		padding-bottom: 10px;

	}

	.btn-buy {
		@extend .btn-danger;
	}

	.btn-more {
		@extend .btn-success;
	}
}

.cart-page-remove-item,
.cart-page-remove-item:hover,
.cart-page-remove-item:active,
.cart-page-remove-item:focus {
	cursor: pointer;
	text-decoration: none;
	margin-left: 10px;

	i {
		padding-top: 9px;
	}
}

.catalogue-widget-container {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
	flex-grow: 0;

	.catalogue-quantity-widget-btn,
	.quantity-widget-amount {
		padding: 6px 12px;
	}

	.catalogue-quantity-widget-btn {
		&:hover,
		&:focus,
		&:active {
			outline: 0 none;
			outline-offset: 0;
		}
	}

	.add-to-cart-btn {
		border: 0 none;
		background-color: #7c6b5c;
		color: #fff;
		display: inline-block;
		margin: 0;
		text-align: center;
		font-weight: normal;
		touch-action: manipulation;
		cursor: pointer;
		white-space: nowrap;
		flex-grow: 1;

		&:hover,
		&:focus,
		&:active {
			border: 0 none;
			background-color: #6e5f51;
			outline: 0 none;
			outline-offset: 0;
		}
	}
}

.catalogue-nav {
	list-style: none;
	margin: 0;
	padding: 0;
}

.cartCheckout {
	td {
		white-space: normal !important;
	}

	.col-name,
	.col-quantity,
	.col-price {
		box-sizing: border-box;
	}

	.col-name {
		display: flex !important;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	th.col-quantity,
	th.col-price {
		text-align: center;
	}

	td.col-quantity,
	td.col-price {
		text-align: right;
		border-top: 0;
	}

	@media screen and (min-width: 768px) {
		td.col-quantity,
		td.col-price {
			border-top: 1px solid #ddd;
		}

		.col-price {
			width: 90px;
		}

		.col-quantity {
			width: 210px;
		}
	}
}

.category-nav {
	list-style: none;
	padding: 0;
	margin-bottom: 0;
	text-align: left;

	@extend .row;

	.li-item {
		@extend .col-md-4;

		
		.nav-items {
			font-size: 24px;
			color: $gray;
			margin-bottom: 10px;
			display: block;
		}

		ul {
			list-style: none;
			margin: 0 0 30px;
			padding: 0;
		}
	}
}

// catalogue filter
.catalogue-filter-container {
	border: solid 3px $gray-lighter;

	@include bp(small_med) {
		display: inline-block;
	}

	.filter-title {
		background-color: $gray-lighter;
		padding: 5px 10px;
		border-bottom: solid 3px $gray-lighter;

		h3 {
			font-size: 14px;
			margin: 0;
		}

		@include bp(small_med) {

			h3 span {
				display: none;
			}

			&:hover {
				cursor: pointer;
				

			}

			h3 {
				&:before {
					content: "\f0c9";
					font-family: "fontawesome";
					display: inline-block;
					margin-right: 10px;
				}	
			}
			
		}
	}
	
	.filter-body {
		padding: 10px;
		background-color: white;
		
		// filter responsive

		@include bp(small_med) {
			border: 0;
			border-left: solid 5px $gray;
			background-color: $black;
			top: 0; 
			float: none;
			position: fixed;
			width: 300px;
			height: 100%;
			right: -300px;
			padding: 0;
			display: block;
			margin: 0 !important;
			vertical-align: middle;
			z-index: 1200;
			-webkit-overflow-scrolling: touch; // creates bounce effect when scrolling to the end of the overflow
			overflow-y: scroll;

			@include transition(.4s);


			&.fadein {
				@include translate-xy(-300px, 0);
			}
			
			&.fadeout {
				@include translate-xy(0, 0);
			}

			.checkbox {
				margin: 0;
				color: $white;
				border-bottom: dotted 1px $gray;
				padding: 10px;
				
			}
		}
	}
}


.catalogue-nav-container {
	// margin-top: -30px;
	// margin-bottom: 80px;
	// padding: 20px;
	background-color: white;
	border-bottom: solid 1px $gray-lighter;

	.catalogue-nav {
		text-align: center;

		li {
			display: inline-block;

			@include bp(medium) {

				&:after {
					font-family: "fontawesome";
					content: "\f069";
					font-size: .8em;
					color: $gray-light;
					padding: 0 30px;
				}

				&:first-of-type {
					&:before {
						font-family: "fontawesome";
						content: "\f069";
						font-size: .8em;
						color: $gray-light;
						padding: 0 30px;
					}
				}
						
				&.active {
					a {
						border-bottom: solid 3px $gray-light;
						color: $brand-primary;
					}
				}
			}


		}

		a {
			padding-top: 20px;
			padding-bottom: 20px;
			color: $gray-light;
			display: inline-block;

			&:hover {
				text-decoration: none;
				color: $black;
			}
		}
	}

	.catalogue-device-btn {
		display: none;
	}

	@include bp(small) {
		
		.catalogue-device-btn {
			display: block;
			border: 0;
			background-color: $black;
			text-align: center;
			width: 100%;
			color: $white;
			padding: 10px 0;

		}

		.catalogue-nav {
			border: 0;
			height: 0;
			overflow-y: auto;

			@include transition(.3s);
			
			&.fadein {
				height: 104px;
			}

			li {
				float: none;
				display: block;
				border-bottom: solid 1px $gray-darker;
				text-align: center;
			}

			a {
				border: 0;
			}
		}
	}
}



.master-award {
	position: absolute;
	left: 10px;
	top: 10px;
	width: 68px;
	height: auto;
}

// catalogue detail

.catalogue-nav-container {
	text-align: center;
	// background-color: $brand-primary;
	// margin-bottom: 50px;

	a {
		color: $white;
	}
}

.catalogue-row {
	@extend .row;
}

.catalogue-detail-container {
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 20px;
	padding-right: 20px;
	padding-left: 20px;

	.master-award {
		left: 30px;
		width: auto;
	}

	@extend .clearfix;


	.catalogue-col-1 {
		@extend .col-md-6;
	}

	.catalogue-col-2 {
		@extend .col-md-6;
	}
	
	.detail-img-container {
		@extend .row;
	}

	.detail-thumb-container {
		@extend .col-md-2;

		@include bp(small_med) {
			display: none;
		}
 	}

	.detail-hero-container {
		@extend .col-md-10;

		text-align: center;
	}

	.detail-thumb {
		display: block;
		padding-bottom: 100%;
		background-color: $white;
		border: solid 1px $gray-lighter;

		@include bg-img(contain, center, no-repeat);
	}

	.detail-hero {
		display: inline-block;
		background-color: $white;
		border: solid 1px $gray-lighter;
	}

	.detail-body-container {

		.alert-danger {
			display: none;
		}

		&.sold-out {

			.detail-ui-container {
				display: none;
			}

			.alert-danger {
				display: block;
			}
		}

		p {
			margin-bottom: 10px;
		}

		h2 {
			margin: 0;
		}
	}

	.detail-ui-container {
		margin-bottom: 20px;

		.btn-add-cart {
			@extend .btn-success;
			@extend .btn-block;
		}

		.btn-checkout {
			@extend .btn-default;
			@extend .btn-block;

			border-color: $black !important;
			color: $black;
		}

		@include bp(large) {
			max-width: 70%;	
		}

		.detail-page-widget {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
		}
	}
}


.panel-body {
	border-top: 0;

	&.panel-social {
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;
	}

	.social-btn-group {
		margin-left: 20px;
		display: inline-block;

		a {
			border: 0;
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

