.header-container {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1000;

	@include bp(large) {
		position: relative;
		top: unset;
		z-index: unset;
	}
}

.newsfeed {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
	height: 50px;
	color: $white;
	font-size: 14px;
	line-height: 1.4;
	display: flex;

	@include bp(small_med) {
		display: none;
	}

	a {
		color: $white;
	}

	.news-title {
		padding-left: 20px;
		font-weight: 600;

		@extend %flex;
		@extend %align-center;

		@include bp(small_med) {
			display: none;
		}
	}

	#news {
		padding-right: 20px;
		padding-left: 5px;
		flex-grow: 1;
	
		@extend %flex;
		@extend %align-center;

		@include bp(small_med) {
			display: none;
		}
	}

	.facebook {
		@extend %flex;
		@extend %align-center;
		padding-left: 20px;
		padding-right: 20px;

		@include transition(.6s);

		&:hover {
			text-decoration: none;
			background-color: rgba(255,255,255,.2);
		}

		.fa {
			font-size: 1.2em;
			margin-right: 5px;
		}

	}
}

.header {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	border-top: solid 1px rgba(255,255,255,.5);
	border-bottom: solid 1px rgba(255,255,255,.5);

	@include bp(small_med) {
		border-top: 0 none;
		border-bottom: 0 none;
	}

	text-align: center;

	.container-fluid {
		padding: 0;
	}
}

.checkout-container {
	.header {
		position: relative;
		border-bottom: solid 1px $gray-lighter;
	}
}

.navbar-header {
	border-right: solid 1px rgba(255,255,255,.5);
	float: left;

	.navbar-brand {
		color: $white;
		height: auto;
		font-size: 14px;
		position: relative;
		border: 0;
		padding: 22px 17px 18px;

		
		@include transition(.3s);

		&:hover {

			background-color: rgba(255,255,255,.2);

		}

		svg {
			path {
				fill: $white;

				@include transition(.3s);
			}
		}
	}
}

.device-nav-toggle {
	display: none;
	margin: 0;
	padding: 19px 18px 10px;
	font-size: 30px;
	color: $white;
	background-color: transparent;
	border: 0;
	float: left;

	&:hover, 
	&:focus, 
	&:active {
		background-color: transparent;
	}

	@include bp(small_med) {
		display: inline-block;
	}

}


body {
	.mobile-nav-overlay {
		position: fixed;
		left: 0;
		height: 100%;
		width: 100%;
		top: -999900px;
		background-color: rgba(0, 0, 0, .5);
		z-index: 11;

		@extend %animated-fast;
	}
	
	&.overflow {
		.mobile-nav-overlay {
			@extend %fade-in;
		}
	}
}


.product1113 {
	#mainnav {
		#navbar-mainnav {
			li.li-item {
				&.active {
					.nav-item {
						color: $white;

					}
				}
				.nav-item {
					&.nav-item-4 {
						color: $brand-warning;
					}
				}
			}
		}
	}
}

#mainnav {
	border: 0;
	margin: 0;
	padding: 0;
	text-align: center;
	display: inline-block;
	
	#navbar-mainnav {
		float: none !important;
		margin-right: 0;


		li.li-item {
			text-align: center;

			&.active,
			&.open {
				.nav-item {
					background-color: transparent;
					color: $brand-warning;

					&:hover,
					&:focus,
					&:active {
						color: $white;
					}

				}
			}

			&.dropdown {
				.nav-item {
					&:after {
						font-family: fontawesome;
						content: "\f107";
					}
				}
			}

		
			.nav-item {
				text-transform: uppercase;
				color: $white;
				font-weight: 600;
				font-size: 14px;
				border: 0;
				padding: 28px 16px 24px;

				@include transition(.3s);

				

				@include bp(xlarge) {
					padding: 28px 28px 24px;
				}


				&:hover,
				&:focus,
				&:active {
					color: $brand-warning;
					background-color: transparent;
				}

			}

			#close-nav {
				display: none;
			}
		}

		.dropdown-menu {
			border-color: $brand-primary;
			background-color: $brand-primary;
			padding: 10px 18px;
			border-radius: 0;
			margin-top: -3px;
			border-top: dotted 1px lighten($brand-primary, 10%);

			a {
				color: $white;
				padding: 5px 0;
				// border-bottom: dotted 1px lighten($brand-primary, 10%);
				font-size: 14px;

				&:hover,
				&:focus,
				&:active {
					background-color: transparent;
				}
			}
			
		}

		// styles for mobile nav
		@include bp(small_med) {
			background-color: $black;
			top: 0; 
			float: none;
			position: fixed;
			width: 300px;
			height: 100%;
			left: -300px;
			padding: 15px;
			display: block;
			margin: 0 !important;
			vertical-align: middle;
			z-index: 99;
			-webkit-overflow-scrolling: touch; // creates bounce effect when scrolling to the end of the overflow
			overflow-y: scroll;
			text-align: center;
			
			@include transition(.4s);
			
			li.li-item {
				display: block;
				width: 100%;

				#close-nav {
					display: inline-block;
				}

				.nav-item {
					margin-bottom: 2px;
					padding-top: 15px;
					padding-bottom: 15px;
					font-size: 16px;
					color: $brand-primary;
					

				}
			
				.dropdown-menu {
					width: 100%;
					left: 0;
					top: 0;
					position: relative;
					margin: 0 0 30px;
				}
			}
			
			&.fadein {
				@include translate-xy(300px, 0);
			}
			
			&.fadeout {
				@include translate-xy(0, 0);
			}
		}
	}
}

