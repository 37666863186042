body {
	height:auto;
	-webkit-font-smoothing: antialiased;
	background-color: $white;

	&.overflow {
		overflow: hidden;
	}
}

::selection {
	background-color: $brand-primary;
	color: $white;
	text-shadow: none;
}

.container-lg {
	
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.container {
	// max-width: 940px;
}

%img-border {
	padding: 10px;
	border: solid 1px #eee;
	border-bottom-color: #ddd;
	box-shadow: 0 1px 1px rgba(0,0,0,.05);
	background-color: $white;
}

.img-right {
	img {
		
		@extend %img-border;

		@include bp(large) {
			float: right;
			margin-left: 20px;

		}
	}
}

.img-left {
	img {
		@extend %img-border;

		@include bp(large) {
			float: left;
			margin-right: 20px;
		}
	}
}

.img-responsive {
	@include bp(large) {
		display: inline-block;
	}
}


.pageid1 {
	#supersized {

		&.loading {
			background-image: url(../../images/loading.gif);
			background-size: 30px;
			background-position: center;
			background-repeat: no-repeat;
		}

		&.active {
			.slides {
				.action-container {
					display: none;

					@include bp(large) {
						display: block;
					
						@include fadeInDown($delay:.5s);
					
					}
				}

				.btn-banner-global {
					display: block !important;
					@include bounceIn($delay:1s);
				}
			}	
		}
		
		@include bp(large) {

			height: 400px;

			@media screen and (min-height: 700px) {
				height: 100vh;
			}
		}

		.slides {
			li {
				height: auto;

				@include bp(small_med) {
					height: 85px;
				}

				@include bp(large) {

					height: 400px;

					@media screen and (min-height: 700px) {
						height: 100vh;
					}
				}
			}

			.btn-banner {
				display: none;
			}

			.btn-banner-global {
				@include center-content(65px,65px);
				background-color: rgba(255,255,255,.05);
				border-radius: 50%;
				margin-top: 200px;
				z-index: 10;
				padding-top: 25px;

				@include transition(.6s);

				&:hover {
					background-color: rgba(255,255,255,.2);
				}
			}
		}
	}
}


// slideshows
#supersized {
	position: relative;
	background-color: $gray-lighter;
	z-index: 1;
	height: 135px;

	@include bp(small_med) {
		height: 85px;

		.slides {
			height: 85px;
		}
	}

	.flex-control-nav {
		bottom: 25px;
		z-index: 11;

		li {
			a {
				background: rgba(255,255,255,.0);
				border: solid 1px rgba(255,255,255,.8);

				&:hover {
					background: rgba(255,255,255,.9);
				}

				&.flex-active {
					background: rgba(255,255,255,.7);
				}
			}
		}
	}

	.slides {

		.action-container {
			text-align: center;
			font-size: 22px;
			width: 100%;
			left: 0;
			display: none;
			z-index: 2;

			@include bp(large) {
				
				@include center-content(350px, 210px);

			}

			img {
				height: auto;
			}

			h1 {
				font-family: $font-family-serif;
				color: $white;
				margin: 0;
				font-size: 40px;
				font-style: italic;

				@include transition (.3s);

				@include bp(large) {
					font-size: 63px;
				}
			}	


		}

		.btn-banner,
		.btn-banner-global {
			display: none;
		}

		li {
			background-size: cover;
			background-position: center;
			text-align: center;
			background-repeat: no-repeat;
			color: $white;
			height: 135px;

			@include bp(small_med) {
				height: 85px;
			}

			&:after {
				// position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(0,0,0,.3);
				content: "";
				z-index: 1;
			}
		}
		
	}
}


// parsley
.parsley-required, 
.parsley-type,
.parsley-length,
.parsley-min,
.parsley-minlength,
.parsley-equalto,
.parsley-range {
	font-size: 11px;
	display: inline-block;
}

.form-control-feedback {
	opacity: 0;
	display: none;
}

#contactForm {
	.extras {
		// display: none;
	}
}

.has-feedback {

	.input-group {
		.form-feedback {
			top: 39px;
		}
	}

	.form-control {
		box-shadow: none;

		&:focus {
			border-left-width: 4px;

			@include box-shadow(-4px 4px 0 rgba(0,0,0,.05));
		}
	}

	&.has-error {
		.form-control-feedback {
			opacity: 1;
			display: inline-block;

			.fa:before {
				content: "\f00d";
			}
		}
		.form-feedback {
			color: $brand-danger;
			position: absolute;
			right: 0;
			z-index: 11;
			
			.parsley-required {
				background-color: $white;	
			}
			
			
		}
	}
	
	&.has-success {
		.form-control-feedback {
			opacity: 1;
			display: inline-block;

			.fa:before {
				content: "\f00c";
			}
		}
	}
}
.shop-qty-container {

	&.has-feedback.has-error {
		.form-feedback {
			top: -50px;
			width: 100%;
			background-color: $brand-danger;
			color: $white;
			line-height: 1.4;
			padding: 5px;
			text-align: center;
		}

		.parsley-range {
			&:after {
				border-right: transparent 5px solid;
				border-left: transparent 5px solid;
				border-top: solid 5px $brand-danger;
				content: "";
				position: absolute;
				left: 46%;
				bottom: -5px;
				
			}
		}
	}
}

//forms

.input-group-addon {
	&.spampic {
		padding: 2px 5px;
	}
}

.spamnotes {
	font-size: $font-size-base;
}

// custom theme

.form-control {
	box-shadow: none;

	&:focus {
		border-left: solid 5px $brand-info;

		@include box-shadow(-4px 4px 0 rgba(0,0,0,.05));
	}
}




// buttons


// sections

.section {

	padding-top: 40px;
	padding-bottom: 40px;
	background-color: $white;
	position: relative;
	background-position: center;
	background-size: cover;

	

	@include bp(small) {
		h1,
		h2 {
			font-size: 30px;
		}
	}

	@include bp(large) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	h1 {
		font-weight: 200;
	}

	h2 {
		color: lighten($gray-light, 15%);
		font-weight: 200;
	}

	.btn-section {
		display: none;
	}

	&.gray-bg {
		background-color: #f9f9f9;
	}

	&.dark-gray-bg {
		background-color: $gray-darker;
		color: $white;

		h1,
		h2 {
			color: $brand-warning;
		}

		.img-right,
		.img-left {

			img {
				border-color: darken($gray,10%);
				background-color: $gray-dark;
				
			}
		}
	}

	&.black-bg {
		background-color: $black;
		color: $white;
	
		h1,
		h2 {
			color: $brand-warning;
		}

		&.red-headings {
			h1,
			h2 {
				color: $brand-danger;
			}
		}
	}

	&.container-sm {
		.container-inner {
			margin-left: auto;
			margin-right: auto;
			max-width: 940px;
		}
	}

	.section-title {
		h1 {
			margin-top: 0;
			font-size: 30px;
			font-weight: 200;
			text-align: center;

			@include bp(large) {
				margin: 0 0 50px;
				font-size: 40px;
			}
		}
	}

	@include bp(medium) {

		.lead {
			font-size: 20px;
		}
	}

	&.no-pad {
		padding: 0;

		.section-title {
			h1 {
				padding-top: 20px;

				@include bp(large) {
					padding-top: 50px;
					
				}
			}
		}

		p {
			margin: 0;
		}

		.flex {

			@extend %flex;
			@extend %align-center;
			@include justify-content(center);
	
			h2 {
				@include bp(large) {
					margin-top: 40%;
				}

			}
		}
	}

	&.full-width {

		.container {
			width: 100%;
			max-width: none;
		}

		.inner-container {
			width: 100%;
		}
	}

	&.no-pad-l-r {
		.container {
			padding: 0;
		}
	}

	&.back-beach,
	&.back-beach-2 {
		padding: 0;

		.section-title {
			padding-top: 50px;
		}

		.row {
			margin: 0;

			@include bp(large) {
				display: flex;
				-webkit-display: flex;
				// flex-wrap: wrap;
				
			}

			.col-md-6 {
				background-color: lighten($brand-primary,50%);
				padding: 0;
				@extend %align-center;
				@include justify-content(center);

				@include bp(large) {
					display: flex;
					-webkit-display: flex;
				}

				.bb-container {
					max-width: 600px;
					padding: 20px 20px 30px;

					@include bp(large) {
						padding: 0 20px 20px;
					}
					
				}

			}
		}
	}

	&.footer-address {
		&:before {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			content: "";
			background-color: rgba(0,0,0,.4);

		}

		color: $gray-lighter;

		a {
			color: $gray-lighter;
		}

		h3 {
			color: $brand-warning;
		}
	}

	blockquote {
		border-left: 0;
		font-size: 1.8em;
		padding-left: 0;
		position: relative;
		margin: 20px 0;
		font-family: $font-family-serif;
		border-top: solid 1px #eee;
		border-bottom: solid 1px #eee;
		padding: 30px 0;
		color: $gray;
		font-style: italic;
		text-align: center;

		@include bp(xlarge) {
			margin: 20px 0;
		}
	}
}



// instagram

.instagram-gallery-link-2 {
	z-index: 2;
	position: absolute;
	left: 0;
	top: 0;
	background-color: $gray-dark;
	text-align: center;
	width: 50vw;
	height: 25vh;

	@extend %flex;
	@extend %align-center;
	@include justify-content(center);

	@include bp(large) {
		width: 40vw;
		height: 50vh;
	}

	&:hover {
		background-color: rgba(255,255,255,.1);
		text-decoration: none;
	}

	h2 {
		margin: 0;

		@include bp(small) {
			font-size: 18px;
		}
	}
}


.instagram-container {

	h2 {
		margin: 0;

		@include bp(small) {
			font-size: 18px;
		}
	}

	.instagram-item {
		float: left;
		width: 50%;
		height: 25vh;
		text-align: center;

		@include bp(large) {
			width: 20%;
			height: 25vh;
		}

		@include bg-img(cover,center,no-repeat);

		&.instagram-gallery-link {
			@extend %flex;
			@extend %align-center;
			@include justify-content(center);
			z-index: 1;

			@include bp(large) {
				width: 40%;
				height: 50vh;
			}

			@include transition(.3s);

			&:hover {
				background-color: rgba(255,255,255,.1);
				text-decoration: none;
			}
		}
	}
}


// gallery 
.gallery-container {

	.item {
		float: left;
		width: 50%;
		text-align: center;

		@include bp(large) {
			width: 20%;
		}
	}

	.gallery-thumb {
		display: block;
		background-size: cover;
		background-position: center;
		padding-bottom: 100%;
	}
}

//social 

.social-container {
	@extend .clearfix;
}

.social-item {
	font-size: 30px;
	margin-right: 20px;
	
}


// subscribe 

.subscribe-container {

	.btn-default {

		border-color: $white !important;
		padding-left: 40px;
		padding-right: 40px;
		background-color: transparent;
		color: $white;

		&:hover,
		&:focus,
		&:active {
			background-color: $white !important;
			border-color: $white;
			color: $black !important;
		}
	}

	.btn-subscribe {
		@extend .btn-default;
	}
}

// footer

#footer {
	border-top: solid 1px #ddd;
	padding-top: 20px;
	padding-bottom: 20px;
	color: lighten($gray-light, 25%);
	text-align: center;
	font-size: 14px;

	.container-fluid {
		max-width: 1180px;
	}

	a {
		color: lighten($gray-light, 25%);
	}

	@include bp(large) {
		.privacy {
			text-align: left;
		}
		.copyright {
			text-align: right;

		}
	}
}





