.pageid32 {
	#supersized .slides li {
		background-position: bottom;

		@media screen and (max-width: 1600px) {
			background-size: auto;
		}
	}
}

.section {
	&.portsea-estate-wine-club {
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		
		@media screen and (max-width: 1600px) {
			background-size: auto;
		}
	}
}

.portsea-estate-wine-club {
	color: white;

	h1 {
		color: white;
	}

	// background-color: #A5764A;
}

.wc-btn {
	border-radius: 40px;
	color: white;
	font-weight: 700;
	padding-left: 40px;
	padding-right: 40px;

	&:hover,
	&:focus,
	&:active {
		color: white;
	}
}

.wc-btn-bronze {
	background-color: #7B5027;
}

.wc-btn-silver {
	background-color: #9A9A9A;
}

.wc-btn-gold {
	background-color: #887747;
}

#benefits {
	td:nth-of-type(2) {
		.wine-club-tree {
			fill: #7B5027
		}
	}
	td:nth-of-type(3) {
		.wine-club-tree {
			fill: #9A9A9A
		}
	}
	td:nth-of-type(4) {
		.wine-club-tree {
			fill: #887747
		}
	}

	svg {
		width: 25px;
		height: 25px;
	}

	.table-responsive {
		display: block;
	
		@include bp(small) {
			display: none;
		}
	}

	.table {

		td {
			border-top: 0 !important;
			vertical-align: middle;
			font-weight: 600;
		}
	}
}

.wine-club-intro {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 130px;
	margin-top: 130px;
	max-width: 900px;
}

.wc-container {
	max-width: 1180px !important;
	text-align: center;
	color: $gray;
	padding: 50px 0 0 0;

	h1 {
		color: $gray;
	}

	.container-sm {
		color: $gray;

		th {
			text-align: left;
		}

		thead {
			th {
				text-align: center;
			}
		}

		tbody {
			tr {
				td:first-of-type {
					text-align: left;
					max-width: 400px;
				}
			}
		}
	}
}

.mt-5 {
	margin-top: 50px;
}

.mt-1 {
	margin-top: 15px;
}

.lead {
	&.mb-5 {
		margin-bottom: 150px;
	}
}

.wc-nav {

	list-style: none;
	margin: 0;
	padding: 0;

	@include bp(medium) {
		display: flex;
		justify-content: center;
	}

	li {
		margin: 0 20px;

		@include bp(small) {
			margin: 0 0 10px 0;
			display: block;

		}
	}

	a {
		background-color: rgba(255, 255, 255, .1);
		border: solid 1px white;
		color: white;
		border-radius: 20px;
		display: block;
		padding: 5px 20px;
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 14px;
		text-align: center;


		.nav-members & {
			background-color: rgba(0, 0, 0, .1);
			border: solid 1px $brand-primary;
			color: $brand-primary;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
				color: white;
				background-color: $brand-primary;
			}

		}

		@include transition(.3s);

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			color: $gray-darker;
			background-color: rgba(255, 255, 255, 1);
			// border-bottom: solid 2px white;
		}
	}
}



.nav-container {
	flex-grow: 1;
	margin-top: 50px;
	margin-bottom: 50px;

	@include bp(medium) {
		display: flex;
		justify-content: center;
	}

	&.nav-members {
		margin-top: 0;
	}

}

.steps-container {
	margin-top: 200px;

	&.active {
		margin-top: 350px;
		padding-bottom: 150px;
	}

	.wc-step-header-container {
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		background-color: #fff;
		z-index: 100;
		padding: 20px 0;

		@include bp(small_med) {
			top: 85px;
			padding: 12px 0;

			.wc-step-heading,
			.lead {
				margin-top: 0;
				margin-bottom: 12px;
			}

			.price-summary-container,
			#price-summary-mount {
				padding: 0;
				height: 30px;
			}
		}
	}
}

.cards-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 50px -30px 0;


}

.card {
	padding: 20px 30px;
	border: solid 1px $gray-lighter;
	background-color: white;
	display: block;
	margin-left: 30px;
	margin-right: 30px;
	margin-bottom: 30px;
	min-width: 300px;
	max-width: 300px;
	position: relative;

	@include bp(medium) {
		padding: 30px 50px;
	}

	&.wc-nav-item {

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			border: solid 1px $brand-primary;
		}
		
		&.active {
			border: solid 1px $brand-primary;
		}
	}


	.card-header {
		font-size: 14px;
		text-transform: uppercase;
		line-height: 1.4;
		font-family: "trajan-pro-3","Times New Roman",Times,serif;
	}

	.card-title {
		margin-bottom: 0;
		font-size: 14px;
		font-family: "proxima-nova",Helvetica,Arial,sans-serif;
	}

	.icon {
		font-size: 100px;
		margin-bottom: 20px;

		.fa {
			opacity: .5;
		}

		.number {
			border-radius: 50%;
			width: 50px;
			height: 50px;
			background-color: $brand-danger;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			font-weight: 600;
			position: absolute;
			top: calc(50% - 35px);
			left: calc(50% - 25px);
		}
	}

	&.Customise,
	&.Maker {
		.icon {
			font-size: 80px;
			margin-bottom: 60px;
		}

		.number {
			left: calc(50% - 100px);
			width: 200px;
			border-radius: 25px;

		}
	}

	&.login-card {
		max-width: 500px;
		min-width: 500px;
		text-align: left;
	}
}

.mobile-benefits-container {
	display: none;

	@include bp(small) {
		display: block;
	}
	.card {
		min-width: 0;
		max-width: 100%;
		padding: 20px;
		margin: 0 0 20px 0;
	}
}

.quantity-widget-inner {
	display: flex;
	width: 150px;
	margin: 0 auto 20px auto;
}

.quantity-widget-amount {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	background-color: rgba(0,0,0,.8);
	color: white;
}

.quantity-widget-corner {
	position: absolute;
	left: 20px;
	top: 20px;
	opacity: 0;
	width: 50px;
	height: 50px;
	background-color: $brand-warning;
	color: white;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;

	&.active {
		opacity: 1;
	}
}

.summary-container {
	display: flex;
	border: solid 1px $gray-lighter;
	font-size: 14px;
	color: lighten($gray-light, 10%);
	font-weight: 600;
	text-align: left;

	@include bp(small) {
		flex-wrap: wrap;
		border-top: 0;

	}

	.summary-item {
		flex-grow: 1;
		width: 25%;
		padding: 20px;

		@include bp(small) {
			width: 50%;
			border-top: solid 1px $gray-lighter;
		}

		&:not(:last-of-type) {
			border-right: solid 1px $gray-lighter;
		}
	}

	.summary-key {
		text-transform: uppercase;
	}

	.summary-value {
		font-weight: 200;
		color: $gray;
		font-size: 28px;
	}
}



.cms-loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255,255,255,.5);
  display: flex;
  align-items: center;
  justify-content: center; 
  color: black;
  font-size: 30px;
  z-index: 9999999;
}

.price-summary-container {
	height: 41px;
}
#price-summary-mount {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 5px;
	z-index: 999;
	height: 41px;
	
	&.fixed {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		border-bottom: solid 1px $gray-lighter;

	}
}
.price-summary {
	background-color: $brand-warning;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 350px;
	font-weight: 600;
	color: white;

}
.wine-order-items {
	display: flex;
	justify-content: center;
	padding: 40px 0 0;
	flex-wrap: wrap;

}
.wine-order-item {
	width: 100px;
	font-size: 14px;
	text-align: center;
}

#price-total-mount {
	margin-top: 50px;
	.price-total {
		font-size: 30px;
		font-weight: 600;
	}
}

.container-sm#benefits {
	padding: 20px;
	background-color: white;
	box-shadow: -10px 15px 40px rgba(0,0,0,.2);
	margin-top: 50px;
	
}

// modals
.modal {
	&.full-width {
		.modal-dialog {
			width: auto;
			max-width: 1600px;
		}
	}
}


// check out form
$silver: 	#bdc3c7;
$clouds:    #ecf0f1;
$white: 	#fff;

$booking-pad: 0;

#member-login {
	h1 {
		margin-top: 150px;
	}

	.summary-register-order {
		padding: 20px;
		border: solid 1px $gray-lighter;
		background-color: white;
		text-align: left;
	}

	.summary-order {
		padding: 20px 0;
		margin: 20px 0;
		border-top: solid 1px $gray-lighter;
		border-bottom: solid 1px $gray-lighter;
	}

	#price-total-mount {
		margin-top: 0;
	}
}

.register-form {
	text-align: left;


	.row {
		margin-left: -$booking-pad;
		margin-right: -$booking-pad;
	}

	.columns {
		padding: 0 $booking-pad;
	}

	.form-control {
		border-radius: $booking-pad;
	}

	.form-mar {
		margin-bottom: 5px;
	}
}



.btn-submit {
	@extend .btn-info;
	@extend .btn-lg;
	@extend .btn-block;

	border-radius: 3px;
	font-size: .9em;
	font-weight: 600;
	padding-top: 13px;
	padding-bottom: 13px;

	@include bp(small) {
		margin-top: 20px;
	}
}

.form-group-default {
	background-color: $white;
	border: solid 1px lighten($silver,15%);
	padding: 0;
	margin: -1px 0 0 -1px;
	position: relative;

	@include transition(.3s);

	&.focused {
		background-color: $clouds;

		.form-label {
			opacity: .5;
		}
	}

	&.has-feedback {

		&.has-error {
			border-left-color: $brand-danger;

			.form-feedback {
				right: 15px;
				top: 5px;
			}
		}
	}

	.form-label {
		margin: 0;
		font-size: 13px;
		font-weight: 600;
		text-transform: uppercase;
		opacity: .8;
		position: absolute;
		top: 15px;
		left: 15px;

		&.fade {
			opacity: .5;
		}
	}

	.form-control {
		background-color: transparent;
		border: 0;
		box-shadow: none;
		padding: 29px 15px 13px;
		height: 74px;

		&:focus {
			background-color: transparent;
			border: 0;
		}

		&.form-textarea {
			height: 100px;
			padding-top: 27px;
		}
	}

	.radio {
		padding: 17px 0; 
	}
}

.form-control {
	background-color: $white;
	border: solid 1px lighten($silver,15%);
	box-shadow: none;
	color: $gray-dark;
	
	&:focus {
		
		box-shadow: none;
		border: solid 1px lighten($silver,10%);
		background-color: lighten($silver,18%);
	}
}



// select2 theme

.form-group-default-select2 {
	padding: 0;
}

.select2-container {
	height: 54px;

	.select2-selection {
		padding-top: 23px;
		
	}
}

.select2-multiple {
	.select2-container {
		height: auto;
	}
}

.select2-container--default {

	&.select2-container--focus {
		.select2-selection--multiple {
			border: 0;
		}
	}

	.select2-selection--single,
	.select2-selection--multiple {
		border: 0;
		background-color: transparent;

		.select2-selection__arrow {
			top: 22px;
		}
	}
}

.select2-container--open .select2-dropdown {
	top: 5px;
	border: 0;
	box-shadow: -2px 2px 6px rgba(0,0,0,.2);
}

.wc-login {
	width: 100%;
	margin: 0 auto;

	@include bp(medium) {
		max-width: 500px;
	}
	.card {
		min-width: 100%;
		text-align: left;
		margin: 0;
	}
}


/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

// memebers area
#products-mount {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.wine-order-item {
		text-align: center;
	} 
}

.modal {
	#price-total-mount {
		margin: 0;
		.price-total {
			text-align: center;
			
		}
	}
}

.tooltip {
	opacity: 1;

	.tooltip-inner {

		background-color: white;
		box-shadow: 0 5px 10px rgba(0,0,0,.2);
		border: solid 1px black;
		border-radius: 5px;
		color: $gray;
	}

}

#original-price {
	text-decoration: line-through;
}